import React, { useContext } from "react";
import tw, { styled } from "twin.macro";
import { ContentContainer } from "../pages";
import SectionHeading from "./section-heading.component";
import CheckMarkSVG from "../assets/inline/check-mark.svg";
import { StyledComponent } from "@emotion/styled";
import { css } from "@emotion/react";
import { RegisterInterestContext } from "../context/register-interest.context";

interface PricingData {
  title: string;
  leadIn: string;
  price?: string;
  priceQualifier?: string;
  isEnterprise: boolean;
  enterpriseText?: string;
  actionText: string;
  actionButton: StyledComponent<any>;
  featureList: string[];
  featureCheckmark: React.FC;
  gradientContainer: StyledComponent<any>;

  // // new
  mobileApp: boolean;
  kiosk: boolean | string;
  maxAssets: string;
  userSeats: string;
  smsNotifications: string;
  emailNotifications: string;
  whiteLabel: boolean;
  integrations: boolean;
}

const gradDiv = (_tw) => styled.div`
  ${_tw}
`;
const actionButton = (_tw) => styled.button`
  ${tw`hover:opacity-90`}
  ${_tw}
`;

const DashMarkContainer = tw.div`h-6 w-6 text-greyDark text-center mr-2`;
const DashMark = () => <DashMarkContainer>-</DashMarkContainer>;

const PRICING_DATA: PricingData[] = [
  {
    title: "Individual",
    leadIn: "All the basics for property professionals.",
    price: "£12.50",
    priceQualifier: "per month",
    isEnterprise: false,
    actionText: "Sign up to Individual",
    actionButton: actionButton(tw`bg-gradGreenDark`),
    featureList: [
      "Unlimited check outs",
      "300 properties",
      "Unlimited contacts",
      "Includes 1 kiosk*",
      "5 user seats",
      "Unlimited email notifications",
      "50 SMS notifications",
    ],
    gradientContainer: gradDiv(tw`from-gradGreenLight to-gradGreenDark`),

    featureCheckmark: () => (
      <CheckMarkSVG tw="h-6 w-6 text-gradGreenDark mr-2" />
    ),

    mobileApp: true,
    kiosk: false,
    maxAssets: "50",
    userSeats: "1",
    smsNotifications: "20",
    emailNotifications: "50",
    whiteLabel: false,
    integrations: true,
  },
  {
    title: "Lite",
    leadIn: "Essentials for small offices",
    price: "£55",
    priceQualifier: "per month / per branch",
    isEnterprise: false,
    actionText: "Sign up to Lite",

    actionButton: actionButton(tw`bg-gradOrangeDark`),
    featureList: [
      "Unlimited check outs",
      "300 properties",
      "Unlimited contacts",
      "Includes 1 kiosk*",
      "5 user seats",
      "Unlimited email notifications",
      "50 SMS notifications",
    ],

    gradientContainer: gradDiv(tw`from-gradOrangeLight to-gradOrangeDark`),
    featureCheckmark: () => (
      <CheckMarkSVG tw="h-6 w-6 text-gradOrangeDark mr-2" />
    ),

    mobileApp: true,
    kiosk: true,
    maxAssets: "400",
    userSeats: "5",
    smsNotifications: "50",
    emailNotifications: "100",
    whiteLabel: false,
    integrations: true,
  },
  {
    title: "Premium",
    leadIn: "All the bells and whistles for larger\noffices",
    price: "£75",
    priceQualifier: "per month / per branch",
    isEnterprise: false,
    actionText: "Sign up to Premium",

    actionButton: actionButton(tw`bg-gradPurpleDark`),
    featureList: [
      "Lite features plus...",
      "Unlimited properties",
      "Unlimited contacts",
      "Whitelabeled kiosk",
      "Customised fobs",
      "Unlimited user seats",
      "Priority technical support",
    ],

    gradientContainer: gradDiv(tw`from-gradPurpleLight to-gradPurpleDark`),
    featureCheckmark: () => (
      <CheckMarkSVG tw="h-6 w-6 text-gradPurpleDark mr-2" />
    ),

    mobileApp: true,
    kiosk: true,
    maxAssets: "∞",
    userSeats: "∞",
    smsNotifications: "∞",
    emailNotifications: "∞",
    whiteLabel: true,
    integrations: true,
  },
  // {
  //   title: "Enterprise",
  //   leadIn: "Fully featured, enterprise grade keymanagement",
  //   isEnterprise: true,
  //   enterpriseText: "Speak to our sales team",
  //   actionText: "Contact sales",
  //   actionButton: styled.a`
  //     ${tw`bg-gradPurpleDark`}
  //   `,
  //   featureList: [],
  //   gradientContainer: gradDiv(tw`from-gradPurpleLight to-gradPurpleDark`),
  //   featureCheckmark: () => (
  //     <CheckMarkSVG tw="h-6 w-6 text-gradPurpleDark mr-2" />
  //   ),
  //
  //   mobileApp: true,
  //   kiosk: true,
  //   maxAssets: "Unlimited",
  //   userSeats: "Unlimited",
  //   smsNotifications: "Unlimited",
  //   emailNotifications: "Unlimited",
  //   whiteLabel: true,
  //   integrations: true,
  // },
];

import { Fragment } from "react";
import { CheckIcon, MinusIcon } from "@heroicons/react/20/solid";

const tiers = [
  {
    name: "Individual",
    id: "tier-basic",
    href: "#",
    priceMonthly: "£15",
    description: "All the basics for property professionals.",
    mostPopular: false,
  },
  {
    name: "Lite",
    id: "tier-essential",
    href: "#",
    priceMonthly: "£55",
    description: "Starter package for small businesses.",
    mostPopular: true,
  },
  {
    name: "Premium",
    id: "tier-premium",
    href: "#",
    priceMonthly: "£75",
    description: "All the bells and whistles for larger offices",
    mostPopular: false,
  },
];
const sections = [
  {
    name: "Features",
    features: [
      {
        name: "Mobile App",
        tiers: { Individual: true, Lite: true, Premium: true },
      },
      {
        name: "Kiosk",
        tiers: { Individual: false, Lite: true, Premium: true },
      },
      {
        name: "Max Properties / Assets",
        tiers: { Individual: "50", Lite: "500", Premium: "Unlimited" },
      },
      {
        name: "Team members",
        tiers: {
          Individual: "1 user",
          Lite: "Up to 10 users",
          Premium: "Unlimited users",
        },
      },
      {
        name: "PMS/CRM Integrations",
        tiers: { Individual: true, Lite: true, Premium: true },
      },
      {
        name: "White label",
        tiers: { Individual: false, Lite: false, Premium: true },
      },
    ],
  },
  {
    name: "Notifications",
    features: [
      {
        name: "SMS Notifications",
        tiers: {
          Individual: "20/month",
          Lite: "50/month",
          Premium: "Unlimited",
        },
      },
      {
        name: "Email Notifications",
        tiers: {
          Individual: "50/month",
          Lite: "100/month",
          Premium: "Unlimited",
        },
      },
    ],
  },
  {
    name: "Support",
    features: [
      {
        name: "24/7 online support",
        tiers: { Individual: true, Lite: true, Premium: true },
      },
      {
        name: "Priority phone support",
        tiers: { Lite: true, Premium: true },
      },
      { name: "1:1 onboarding tour", tiers: { Premium: true } },
    ],
  },
];

export const NewPricing = () => {
  return (
    <div tw="bg-white py-24 sm:py-32">
      <div tw="mx-auto max-w-7xl px-6 lg:px-8">
        <div tw="mx-auto max-w-4xl text-center">
          <h2 tw="text-base font-semibold leading-7 text-indigo-600">
            Pricing
          </h2>
          <p tw="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Plans for teams of&nbsp;all&nbsp;sizes
          </p>
        </div>
        <p tw="mx-auto mt-6 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Simple no-nonsense pricing. All inclusive, no surprises.
        </p>

        {/* xs to lg */}
        <div tw="mx-auto mt-12 max-w-md space-y-8 sm:mt-16 lg:hidden">
          {tiers.map((tier) => (
            <section
              key={tier.id}
              css={{
                ...(tier.mostPopular
                  ? tw`rounded-xl bg-gray-400/5 ring-1 ring-inset ring-gray-200`
                  : ""),
                ...tw`p-8`,
              }}
            >
              <h3
                id={tier.id}
                tw="text-sm font-semibold leading-6 text-gray-900"
              >
                {tier.name}
              </h3>
              <p tw="mt-2 flex items-baseline gap-x-1 text-gray-900">
                <span tw="text-4xl font-bold">{tier.priceMonthly}</span>
                <span tw="text-sm font-semibold">/month</span>
              </p>
              <a
                href={tier.href}
                aria-describedby={tier.id}
                css={{
                  ...(tier.mostPopular
                    ? tw`bg-indigo-600 text-white hover:bg-indigo-500`
                    : tw`text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300`),
                  ...tw`mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`,
                }}
              >
                Buy plan
              </a>
              <ul
                role="list"
                tw="mt-10 space-y-4 text-sm leading-6 text-gray-900"
              >
                {sections.map((section) => (
                  <li key={section.name}>
                    <ul role="list" tw="space-y-4">
                      {section.features.map((feature) =>
                        feature.tiers[tier.name] ? (
                          <li key={feature.name} tw="flex gap-x-3">
                            <CheckIcon
                              aria-hidden="true"
                              tw="h-6 w-5 flex-none text-indigo-600"
                            />
                            <span>
                              {feature.name}{" "}
                              {typeof feature.tiers[tier.name] === "string" ? (
                                <span tw="text-sm leading-6 text-gray-500">
                                  ({feature.tiers[tier.name]})
                                </span>
                              ) : null}
                            </span>
                          </li>
                        ) : null
                      )}
                    </ul>
                  </li>
                ))}
              </ul>
            </section>
          ))}
        </div>

        {/* lg+ */}
        <div tw="isolate mt-20 hidden lg:block">
          <div tw="relative -mx-8">
            {tiers.some((tier) => tier.mostPopular) ? (
              <div tw="absolute inset-x-4 inset-y-0 -z-10 flex">
                <div
                  style={{
                    marginLeft: `${
                      (tiers.findIndex((tier) => tier.mostPopular) + 1) * 25
                    }%`,
                  }}
                  aria-hidden="true"
                  tw="flex w-1/4 px-4"
                >
                  <div tw="w-full rounded-t-xl border-x border-t border-gray-900/10 bg-gray-400/5" />
                </div>
              </div>
            ) : null}
            <table tw="w-full table-fixed border-separate border-spacing-x-8 text-left">
              <caption tw="sr-only">Pricing plan comparison</caption>
              <colgroup>
                <col tw="w-1/4" />
                <col tw="w-1/4" />
                <col tw="w-1/4" />
                <col tw="w-1/4" />
              </colgroup>
              <thead>
                <tr>
                  <td />
                  {tiers.map((tier) => (
                    <th
                      key={tier.id}
                      scope="col"
                      tw="px-6 pt-6 xl:px-8 xl:pt-8"
                    >
                      <div tw="text-sm font-semibold leading-7 text-gray-900">
                        {tier.name}
                      </div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">
                    <span tw="sr-only">Price</span>
                  </th>
                  {tiers.map((tier) => (
                    <td key={tier.id} tw="px-6 pt-2 xl:px-8">
                      <div tw="flex items-baseline gap-x-1 text-gray-900">
                        <span tw="text-4xl font-bold">{tier.priceMonthly}</span>
                        <span tw="text-sm font-semibold leading-6">/month</span>
                      </div>
                      <a
                        href={tier.href}
                        css={{
                          ...(tier.mostPopular
                            ? tw`bg-indigo-600 text-white hover:bg-indigo-500`
                            : tw`text-indigo-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300`),
                          ...tw`mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`,
                        }}
                      >
                        Buy plan
                      </a>
                    </td>
                  ))}
                </tr>
                {sections.map((section, sectionIdx) => (
                  <Fragment key={section.name}>
                    <tr>
                      <th
                        scope="colgroup"
                        colSpan={4}
                        css={{
                          ...(sectionIdx === 0 ? tw`pt-8` : tw`pt-16`),
                          ...tw`pb-4 text-sm font-semibold leading-6 text-gray-900`,
                        }}
                      >
                        {section.name}
                        <div tw="absolute inset-x-8 mt-4 h-px bg-gray-900/10" />
                      </th>
                    </tr>
                    {section.features.map((feature) => (
                      <tr key={feature.name}>
                        <th
                          scope="row"
                          tw="py-4 text-sm font-normal leading-6 text-gray-900"
                        >
                          {feature.name}
                          <div tw="absolute inset-x-8 mt-4 h-px bg-gray-900/5" />
                        </th>
                        {tiers.map((tier) => (
                          <td key={tier.id} tw="px-6 py-4 xl:px-8">
                            {typeof feature.tiers[tier.name] === "string" ? (
                              <div tw="text-center text-sm leading-6 text-gray-500">
                                {feature.tiers[tier.name]}
                              </div>
                            ) : (
                              <>
                                {feature.tiers[tier.name] === true ? (
                                  <CheckIcon
                                    aria-hidden="true"
                                    tw="mx-auto h-5 w-5 text-indigo-600"
                                  />
                                ) : (
                                  <MinusIcon
                                    aria-hidden="true"
                                    tw="mx-auto h-5 w-5 text-gray-400"
                                  />
                                )}

                                <span tw="sr-only">
                                  {feature.tiers[tier.name] === true
                                    ? "Included"
                                    : "Not included"}{" "}
                                  in {tier.name}
                                </span>
                              </>
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const Pricing: React.FC = () => {
  return (
    <div tw="bg-greyBg">
      <ContentContainer id="pricing" tw="flex flex-col items-center lg:px-12">
        <SectionHeading
          title="Pricing"
          subtitle="Simple no-nonsense pricing"
          blurb="All inclusive, no suprises."
        />
        <div tw="w-full grid gap-12 grid-cols-1 lg:grid-cols-3 mb-12">
          {PRICING_DATA.map((data, index) => (
            <PricingItem pricingData={data} key={index} />
          ))}
        </div>
        <p>
          30 day money back guarantee for all packages. £150 setup fee
          applicable.
        </p>
        <p>*Kiosk rental included in price. Tags purchased during setup.</p>
      </ContentContainer>
    </div>
  );
};

const FEATURE_TEXTS: Partial<Record<keyof PricingData, string>> = {
  mobileApp: "Mobile App",
  kiosk: "Kiosk",
  maxAssets: "Max Properties / Assets",
  userSeats: "Team members",
  smsNotifications: "SMS Notifications / month",
  emailNotifications: "Email Notifications / month",
  whiteLabel: "White label",
  integrations: "PMS/CRM Integrations",
};

const FeatureItem: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [key, value] = React.Children.toArray(children);
  return (
    <li tw="flex items-center mb-2 align-middle">
      <span tw="w-10 text-lg text-greyDark">{key}</span>
      <span tw="text-sm text-gray-500">{value}</span>
    </li>
  );
};

const PricingItem: React.FC<{
  pricingData: PricingData;
}> = ({
  pricingData: {
    title,
    leadIn,
    price,
    priceQualifier,
    isEnterprise,
    enterpriseText,
    actionText,
    actionButton: ActionButton,
    featureList,
    featureCheckmark: FeatureCheckmark,
    gradientContainer: GradientContainer,

    mobileApp,
    kiosk,
    maxAssets,
    userSeats,
    smsNotifications,
    emailNotifications,
    whiteLabel,
    integrations,
  },
}) => {
  const { setShowModal } = useContext(RegisterInterestContext);
  return (
    <div tw="flex flex-col overflow-hidden w-full rounded-lg border border-gray-200 bg-white filter drop-shadow">
      <GradientContainer tw="w-full bg-gradient-to-br h-8" />
      <div tw="flex flex-col p-8 w-full">
        <h2 tw="text-4xl font-bold mb-4">{title}</h2>
        <p tw="text-sm text-greyDark mb-4">{leadIn}</p>
        <h2 tw="text-5xl font-bold mb-2">{price}</h2>
        <h3 tw="text-sm text-greyLight">{priceQualifier}</h3>
        {isEnterprise ? (
          <h3 tw="text-base font-bold mt-2 mb-2">{enterpriseText}</h3>
        ) : null}
      </div>
      {!isEnterprise ? (
        <div tw="bg-white py-6 px-12 flex-1 border-t border-gray-200">
          <h3 tw="text-base font-bold mb-4">What's included:</h3>
          <ul tw="flex flex-col">
            {/*mobileApp*/}
            <FeatureItem>
              {mobileApp ? <FeatureCheckmark /> : <DashMark />}
              {FEATURE_TEXTS["mobileApp"]}
            </FeatureItem>

            {/*kiosk*/}
            <FeatureItem>
              {kiosk ? <FeatureCheckmark /> : <DashMark />}
              {FEATURE_TEXTS["kiosk"]}
            </FeatureItem>

            {/*maxAssets*/}
            <FeatureItem>
              {maxAssets}
              {FEATURE_TEXTS["maxAssets"]}
            </FeatureItem>

            {/*userSeats*/}
            <FeatureItem>
              {userSeats}
              {FEATURE_TEXTS["userSeats"]}
            </FeatureItem>

            {/*smsNotifications*/}
            <FeatureItem>
              {smsNotifications}
              {FEATURE_TEXTS["smsNotifications"]}
            </FeatureItem>

            {/*emailNotifications*/}
            <FeatureItem>
              {emailNotifications}
              {FEATURE_TEXTS["emailNotifications"]}
            </FeatureItem>

            {/*whiteLabel*/}
            <FeatureItem>
              {whiteLabel ? <FeatureCheckmark /> : <DashMark />}
              {FEATURE_TEXTS["whiteLabel"]}
            </FeatureItem>

            {/*integrations*/}
            <FeatureItem>
              {integrations ? <FeatureCheckmark /> : <DashMark />}
              {FEATURE_TEXTS["integrations"]}
            </FeatureItem>

            {/*{featureList.map((text, index) => (*/}
            {/*  <li tw="flex items-center mb-2" key={index}>*/}
            {/*    <FeatureCheckmark />*/}
            {/*    <span tw="text-sm text-gray-500">{text}</span>*/}
            {/*  </li>*/}
            {/*))}*/}
          </ul>
        </div>
      ) : null}
      <div tw="p-8 mt-auto">
        {isEnterprise ? (
          <ActionButton
            href="mailto:info@keyhive.io"
            target="_blank"
            tw="col-span-6 lg:col-span-2 h-12 border border-transparent rounded-md w-full text-center py-4 flex items-center justify-center leading-6 font-medium text-white filter drop-shadow-sm"
          >
            {actionText}
          </ActionButton>
        ) : (
          <ActionButton
            onClick={setShowModal.bind(null, true)}
            tw="col-span-6 lg:col-span-2 h-12 border border-transparent rounded-md w-full text-center py-4 flex items-center justify-center leading-6 font-medium text-white filter drop-shadow-sm"
          >
            {actionText}
          </ActionButton>
        )}
      </div>
    </div>
  );
};

export default Pricing;
