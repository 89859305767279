import React, { useContext } from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { PALETTE } from "./global-styles.component";
import "twin.macro";
import { Link } from "gatsby";
import LogoWhiteSVG from "../assets/inline/logo-white.svg";
import FooterOrbsSVG from "../assets/footer-orbs.svg";
import { RegisterInterestContext } from "../context/register-interest.context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLinkedinIn, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FooterCTA } from "./footerCTA.component";

const FooterContainer = styled.div`
  background: url(${FooterOrbsSVG as any}),
    linear-gradient(114.88deg, #351645 24.57%, #5a4faf 67.22%);
  background-position: center center;
  background-size: auto;
  background-repeat: no-repeat;
  background-blend-mode: overlay;
`;

const FooterBottomBarBG = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  mix-blend-mode: overlay;
  opacity: 0.4;
`;

const LinkedInIcon: React.FC = () => (
  <FontAwesomeIcon
    icon={faLinkedinIn}
    tw="text-white hover:text-brandOrange"
    size="lg"
  />
);

const TwitterIcon: React.FC = () => (
  <FontAwesomeIcon
    icon={faTwitter}
    tw="text-white hover:text-brandOrange"
    size="lg"
  />
);

const Footer: React.FC = ({}) => {
  return (
    <FooterContainer tw="h-96 w-full flex flex-col overflow-hidden">
      <FooterCTA />
      <div tw="w-full md:h-24 justify-self-end self-end relative">
        <FooterBottomBarBG tw="z-10" />
        <div tw="container mx-auto h-full flex flex-col md:flex-row items-center justify-center relative z-20">
          <div tw="flex-none self-center justify-self-start">
            <Link
              to="/"
              css={css`
                color: ${PALETTE.WHITE};
                text-decoration: none;
                margin-left: auto;
              `}
            >
              <LogoWhiteSVG tw="w-32" />
            </Link>
          </div>
          <div tw="text-sm text-white text-center flex-grow self-center md:pt-8">
            © {new Date().getFullYear()} Keyhive.io Ltd. All rights reserved.
          </div>
          <div tw="py-4 md:p-0">
            <a
              href="https://www.linkedin.com/company/keyhive-io"
              target="_blank"
              tw="mr-4"
            >
              <LinkedInIcon />
            </a>
            <a href="https://twitter.com/keyhiveio" target="_blank">
              <TwitterIcon />
            </a>
          </div>
        </div>
      </div>
    </FooterContainer>
  );
};

export default Footer;
