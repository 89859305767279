import React from 'react';
import tw from 'twin.macro';

const Title = tw.h2`
    text-xl tracking-widest text-greyLight uppercase text-center
`;
const SubTitle = tw.h2`
    text-4xl font-bold text-center
`;
const Blurb = tw.h5`text-base text-center text-gray-500 p-0 m-0 pt-8`;

const SectionHeading: React.FC<{
    title: string;
    subtitle: string;
    blurb?: string;
}> = ({ title, subtitle, blurb }) => (
    <div tw="pb-16">
        <Title>{title}</Title>
        <SubTitle>{subtitle}</SubTitle>
        {blurb && <Blurb>{blurb}</Blurb>}
    </div>
);

export default SectionHeading;
