import OrbsSVG from "../assets/orbs.svg";
import React from "react";
import styled from "@emotion/styled";
import HeroDevicesImage from "./hero-devices-image.component";
import { ContentContainer } from "../pages";
import { OpenSans, Palanquin, PalanquinBold } from "./global-styles.component";
import tw, { theme } from "twin.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import AnimatedOrbsBg from "./animated-orbs-bg";
import HeroVideo from "./hero-video.component";
import RegisterInterest from "./register-interest.component";
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import HeroStats from "./HeroStats.component";
import Img from "gatsby-image";
import { graphql, useStaticQuery } from "gatsby";
import HeroPhoneImage from "./hero-phone-image.component";

const HeroBg = styled.div`
  background: linear-gradient(114.88deg, #52206d 24.57%, #5c4faf 67.22%);
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
`;
const OrbsBG = styled.div`
  position: absolute;
  background-image: url(${OrbsSVG as any});
  background-size: contain;
  width: 100%;
  height: 100%;
  opacity: 0.5;
`;
const HeroTextContainer = styled.div`
  width: 100%;

  //top: 100px;
  ${tw`flex flex-col items-start`}
`;
export const HeroTextLeadin = styled.h1`
  ${Palanquin};
  font-size: 60px;
  letter-spacing: -1.5px;
  color: #ffffff;
  margin: 0;
  ${tw`text-2xl xl:text-4xl w-full text-center lg:text-left opacity-80`}
`;
export const HeroTextMain = styled.h1`
  ${PalanquinBold};
  mix-blend-mode: normal;
  background: linear-gradient(180deg, #ed8019 0%, #ebb350 100%);
  background-size: 100%;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  ${tw`m-0 py-2 text-5xl md:text-7xl lg:text-6xl xl:text-7xl leading-normal md:leading-normal lg:leading-normal w-full text-center lg:text-left drop-shadow-md`}
`;
const HeroTextSub = styled.h2`
  ${OpenSans};
  font-size: 20px;
  line-height: 1.5;
  letter-spacing: 0.3px;
  color: #ffffff;
  ${tw`text-base md:text-xl m-0 py-4 md:py-8 w-full text-center lg:text-left leading-relaxed`}
`;

const HeroImage = styled(HeroDevicesImage)`
  ${tw`self-center relative z-20`};
  margin: 0;
  > * {
    margin: 0;
  }

  @media (min-width: ${theme("screens.lg")}) {
    width: 100%;
  }
  @media (min-width: ${theme("screens.xl")}) {
    width: 1658px;
    left: -32px;
  }
`;

const HeroPhone = styled(HeroPhoneImage)`
  ${tw`self-center absolute z-30 drop-shadow-md`};

  margin: 0;
  > * {
    margin: 0;
  }
  width: 15%;
  left: 52%;
  bottom: 12%;

  // @media (min-width: ${theme("screens.lg")}) {
  //   width: 15%;
  //   left: 40%;
  // }
  // @media (min-width: ${theme("screens.xl")}) {
  //   width: 200px;
  //   left: 40%;
  // }
`;

const HeroVideoContainer = styled.div`
  position: relative;
  ${tw`flex flex-col self-start z-40 w-full lg:pt-8`}
`;

const HeroAboveFoldContainer = styled.div`
  ${tw`grid grid-cols-1 lg:grid-cols-2 lg:gap-8 xl:gap-16 py-4 lg:p-12 my-4 md:my-8`}
`;

export const Hero: React.FC = () => {
  return (
    <div tw="overflow-hidden">
      <HeroBg>
        <AnimatedOrbsBg />
        <ContentContainer tw="flex flex-col pt-8 lg:pt-16 pb-0 lg:pt-24 lg:pb-0 z-20">
          <HeroAboveFoldContainer>
            <HeroTextContainer>
              <HeroTextLeadin>Key management as easy as</HeroTextLeadin>
              <HeroTextMain>Tap. Sign. Go.</HeroTextMain>

              <HeroTextSub>
                {`The simple and secure key management solution \nmaking property professional’s lives easier`}
              </HeroTextSub>

              <RegisterInterest
                buttonTW={tw`bg-brandPurpleDark hover:bg-brandPurpleDarker`}
              />

              <HeroSocialProof />
            </HeroTextContainer>
            <HeroVideoContainer>
              <HeroVideo />
            </HeroVideoContainer>
          </HeroAboveFoldContainer>

          <HeroImage />
          <HeroStats />
        </ContentContainer>
      </HeroBg>
    </div>
  );
};

const Star: React.FC<{ size: SizeProp }> = ({ size }) => (
  <FontAwesomeIcon icon={faStar} tw="text-yellow-500 mr-1" size={size} />
);

export const FiveStar: React.FC<{ size: SizeProp }> = ({ size, ...props }) => (
  <ul {...props}>
    <li>
      <Star size={size} />
    </li>
    <li>
      <Star size={size} />
    </li>
    <li>
      <Star size={size} />
    </li>
    <li>
      <Star size={size} />
    </li>
    <li>
      <Star size={size} />
    </li>
  </ul>
);

const HeroSocialProof: React.FC = ({}) => {
  const data = useStaticQuery(graphql`
    query {
      headshotImage: file(relativePath: { eq: "kerfuffle_logo.png" }) {
        childImageSharp {
          fluid(maxWidth: 6000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <a href="https://www.kerfuffle.com/deals/keyhive-io" target="_blank">
      <div tw="flex justify-center items-center h-8 self-center lg:self-start mb-8 lg:mb-0">
        <FiveStar size="sm" tw="flex justify-center items-center" />
        <div tw="h-6 text-sm text-white flex justify-center items-center border-l-2 border-white ml-1 pl-2">
          Rated&nbsp;<strong>5 stars</strong>&nbsp;on&nbsp;
          <Img fluid={data.headshotImage.childImageSharp.fluid} tw="w-16" />
        </div>
      </div>
    </a>
  );
};
