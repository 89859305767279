import React, { ChangeEvent, useContext, useState } from 'react';
import tw, { TwStyle } from 'twin.macro';
import Modal from './modal.component';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { RegisterInterestContext } from '../context/register-interest.context';

const SubmitButton = styled.button`
    ${tw`bg-brandOrange hover:bg-brandOrangeTweak font-bold text-white uppercase py-2 px-4 rounded-lg w-full`}
`;
const CancelButton = styled.button`
    ${tw`text-black py-2 px-4 rounded-lg w-full`}
`;

const RegisterInterest: React.FC<{ buttonTW?: TwStyle }> = ({ buttonTW }) => {
    const { setShowModal, setEmail, email, success } = useContext(
        RegisterInterestContext
    );

    return (
        <>
            {success ? (
                <div css={tw`w-full p-4 rounded bg-green-500 text-white`}>
                    Success! You'll hear from us soon 👍
                </div>
            ) : (
                <div tw="grid grid-cols-6 gap-x-4 gap-y-8 w-full pb-8">
                    <input
                        type="email"
                        placeholder="Enter your email"
                        tw="col-span-6 lg:col-span-4 h-12 drop-shadow-md focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm md:text-lg border-gray-300 rounded-md"
                        css={css`
                            border-radius: 0.375rem !important;
                        `}
                        value={email}
                        onChange={event => setEmail(event.target.value)}
                    />
                    <button
                        onClick={setShowModal.bind(null, true)}
                        tw="col-span-6 lg:col-span-2 h-12 border border-transparent rounded-md w-full text-center py-4 flex items-center justify-center leading-6 font-medium text-white filter drop-shadow-sm"
                        css={[buttonTW]}
                    >
                        Request Demo
                    </button>
                    {/*<div tw="col-span-6 font-medium text-white">*/}
                    {/*    We’ll be in touch shortly to arrange your demo. By*/}
                    {/*    providing your email, you agree to our terms of service.*/}
                    {/*</div>*/}
                </div>
            )}
        </>
    );
};

export const RegisterInterestModal: React.FC<{
    onSubmit: ({ foreName, surName, email, company, telephone }) => void;
    onCancel: () => void;
    emailPrefill: string;
}> = ({ onSubmit, onCancel, emailPrefill }) => {
    const [foreName, setForeName] = useState<string>('');
    const [surName, setSurName] = useState<string>('');
    const [email, setEmail] = useState<string>(emailPrefill);
    const [company, setCompany] = useState<string>('');
    const [telephone, setTelephone] = useState<string>('');

    const _onSubmit = event => {
        event.preventDefault();
        onSubmit({
            foreName,
            surName,
            email,
            company,
            telephone
        });
    };

    return (
        <div
            tw="inline-block align-bottom bg-white rounded-lg px-4 pt-4 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6"
            role="dialog"
            aria-modal="true"
            aria-labelledby="modal-headline"
        >
            <form tw="space-y-8 divide-y divide-gray-200" onSubmit={_onSubmit}>
                <div>
                    <div>
                        <h3 tw="text-lg leading-6 font-medium text-gray-900">
                            Keyhive demo request 🚀
                        </h3>
                        <p tw="mt-1 text-sm text-gray-500">
                            Let us know your details and we'll be in touch.
                        </p>
                    </div>
                    <div tw="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                        <RegisterInput
                            label="First name"
                            id="first_name"
                            name="first_name"
                            value={foreName}
                            onChange={event => setForeName(event.target.value)}
                            containerTW={tw`sm:col-span-3`}
                        />

                        <RegisterInput
                            label="Last name"
                            id="last_name"
                            name="last_name"
                            value={surName}
                            onChange={event => setSurName(event.target.value)}
                            containerTW={tw`sm:col-span-3`}
                        />

                        <RegisterInput
                            label="Email"
                            id="email"
                            name="email"
                            type="email"
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            containerTW={tw`sm:col-span-6`}
                        />

                        <RegisterInput
                            label="Company"
                            id="company"
                            name="company"
                            value={company}
                            onChange={event => setCompany(event.target.value)}
                            containerTW={tw`sm:col-span-6`}
                        />

                        <RegisterInput
                            label="Phone number"
                            id="telephone"
                            name="telephone"
                            type="tel"
                            value={telephone}
                            onChange={event => setTelephone(event.target.value)}
                            containerTW={tw`sm:col-span-6`}
                        />

                        <div tw="sm:col-span-6">
                            <SubmitButton type="submit">Submit</SubmitButton>
                        </div>
                        <div tw="sm:col-span-6">
                            <CancelButton onClick={onCancel}>
                                cancel
                            </CancelButton>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
};

interface IRegisterInput
    extends Partial<React.InputHTMLAttributes<HTMLInputElement>> {
    label: string;
    name: string;
    id: string;
    containerTW: TwStyle;
    onChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const RegisterInput: React.FC<IRegisterInput> = ({
    label,
    name,
    id,
    containerTW,
    onChange,
    type = 'text',
    ...props
}) => (
    <div css={[containerTW]}>
        <label htmlFor={name} tw="block text-sm font-medium text-gray-700">
            {label}
        </label>
        <div tw="mt-1">
            <input
                onChange={onChange}
                type={type}
                name={name}
                id={id}
                autoComplete={label}
                tw="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                css={css`
                    border-radius: 0.375rem !important;
                `}
                {...props}
            />
        </div>
    </div>
);

export default RegisterInterest;
