import React, { useState } from 'react';
import Modal from '../components/modal.component';
import { RegisterInterestModal } from '../components/register-interest.component';
import { css } from '@emotion/react';
import tw from 'twin.macro';

export const RegisterInterestContext = React.createContext<{
    setShowModal;
    setEmail;
    success: boolean;
    email: string;
}>({
    setShowModal: () => null,
    setEmail: () => null,
    success: false,
    email: ''
});

export const RegisterInterestContextProvider: React.FC = ({ children }) => {
    const [email, setEmail] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);

    const onSubmit = params => {
        const qs = new URLSearchParams(params);
        fetch(
            // `https://idu1qe8uj8.execute-api.eu-west-1.amazonaws.com/production/register-interest?${qs.toString()}`,
            `https://ls4qfg388g.execute-api.eu-west-1.amazonaws.com/production/register-interest?${qs.toString()}`,
            { method: 'POST', credentials: 'include' }
        );
        setSuccess(true);
        setShowModal(false);
    };

    return (
        <>
            <RegisterInterestContext.Provider
                value={{ setShowModal, setEmail, success, email }}
            >
                <div css={tw`z-10`}>{children}</div>
            </RegisterInterestContext.Provider>
            {showModal ? (
                <Modal>
                    <RegisterInterestModal
                        onSubmit={onSubmit}
                        onCancel={() => setShowModal(false)}
                        emailPrefill={email}
                    />
                </Modal>
            ) : null}
            <div id="portal" css={tw`z-50`} />
        </>
    );
};
