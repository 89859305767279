import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import tw from 'twin.macro';

const ModalContainer = tw.div`
    fixed w-screen h-screen min-h-screen top-0 left-0 flex items-center justify-center z-10
`;
const ModalBG = tw.div`
    pointer-events-none absolute top-0 left-0 w-full h-full bg-gray-500 opacity-75 z-10
`;
const ModalContent = tw.div`z-20`;

const Modal: React.FC<{}> = ({ children }) => {
    const node = useRef<Element>(document.createElement('div'));

    useEffect(function setupElement() {
        const parentElem = document.querySelector('#portal');
        parentElem?.appendChild(node.current);
        return () => {
            node.current.remove();
        };
    }, []);

    return node.current
        ? ReactDOM.createPortal(
              <ModalContainer>
                  <ModalBG />
                  <ModalContent>{children}</ModalContent>
              </ModalContainer>,
              node.current
          )
        : null;
};

export default Modal;
