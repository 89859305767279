/** @jsx jsx */
import { Themed, jsx } from "theme-ui";
import Layout from "./layout";
import SEO from "gatsby-theme-blog/src/components/seo";
import Footer from "./home-footer";
import PostList from "./post-list";

const Posts = ({ location, posts, siteTitle, socialLinks }) => (
  <Layout location={location} title={siteTitle}>
    <SEO title="Home" />
    <main>
      <PostList posts={posts} />
    </main>
  </Layout>
);

export default Posts;
