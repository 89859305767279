import { base } from "@theme-ui/presets";

const headingMargin = {
  marginBottom: 4,
};

export default {
  // base theme values...
  ...base,
  colors: {
    text: "#222222",
    background: "#fff",
    primary: "#639",
    secondary: "#ff6347",
  },
  fonts: {
    body: "'Open Sans', sans-serif",
    heading: "'Palanquin', Arial, sans-serif",
    monospace: "Menlo, monospace",
  },
  styles: {
    // the keys used here reference elements in MDX
    ...base.styles,
    h1: { ...headingMargin, ...base.styles.h1 },
    h2: { ...headingMargin, ...base.styles.h2 },
    h3: { ...headingMargin, ...base.styles.h3 },
    h4: { ...headingMargin, ...base.styles.h4 },
    h5: { ...headingMargin, ...base.styles.h5 },
    h6: { ...headingMargin, ...base.styles.h6 },
    a: {
      color: "secondary",
      display: "inline-block",
      ":hover, :focus": {
        color: "primary",
      },
    },
    p: {
      fontFamily: "body",
      fontSize: 2,
      lineHeight: 1.5,
      marginBottom: 4,
    },
    ul: {
      marginLeft: 2,
      paddingLeft: 3,
      listStyleType: "disc",
      marginBottom: 3,
    },
    li: {
      marginBottom: 2,
    },
    blockquote: {
      paddingLeft: 4,
      borderLeft: "4px solid #0D7190",
      fontStyle: "italic",
    },
  },
};
