import React from 'react';
import { css } from 'twin.macro';
import TWGlobalStyles from './twin-global.component';
import { Global } from '@emotion/react';

export const Palanquin = css`
    font-family: Palanquin;
    font-style: normal;
    font-weight: 500;
`;
export const PalanquinBold = css`
    font-family: Palanquin;
    font-style: normal;
    font-weight: 700;
`;
export const OpenSans = css`
    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
`;

export const PALETTE = {
    GREY_LIGHT: `#B2B2B2`,
    TEXT: '#4F4F4F',
    BLACK: '#000000',
    WHITE: '#FFFFFF',
    FOOTER_BG_BLUE: '#2385A4'
};

const GLOBAL_STYLES = css`
    body {
        font-family: 'Open Sans', sans-serif;
        font-size: 16px;
        color: ${PALETTE.TEXT};
        //overflow: hidden;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: 'Palanquin', Arial, sans-serif;
        color: ${PALETTE.BLACK};
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }
`;

const GlobalStyles: React.FC<{}> = () => (
    <>
        <Global styles={GLOBAL_STYLES} />
        <TWGlobalStyles />
    </>
);

export default GlobalStyles;
