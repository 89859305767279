import React from 'react';
import { ContentContainer } from '../pages';
import TapInSVG from '../assets/inline/features/feature-tap-in.svg';
import AccountabilitySVG from '../assets/inline/features/feature-accountability.svg';
import ReportingSVG from '../assets/inline/features/feature-live-reporting.svg';
import OverdueSVG from '../assets/inline/features/feature-overdue.svg';
import OnboardingSVG from '../assets/inline/features/feature-painless-onboarding.svg';
import SaveMoneySVG from '../assets/inline/features/feature-save-money-and-time.svg';
import SetYourselfSVG from '../assets/inline/features/feature-set-yourself-apart.svg';
import 'twin.macro';
import SectionHeading from './section-heading.component';
import tw, { css, theme } from 'twin.macro';
import styled from '@emotion/styled';
import SignOutAndReturnKeysImage from './../images/features/sign-out-and-return-keys.png';
import YourKeysUnderControlImage from './../images/features/your-keys-under-control.png';
import ChaseLateKeysImage from './../images/features/chase-late-keys.png';
import AccountabilityImage from './../images/features/accountable-checkouts.png';

const FEATURE_DROP_SHADOW = css`
    filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.1));
`;

enum FEATURE_VARIATION {
    LARGE = 'LARGE',
    SMALL = 'SMALL'
}

interface IFeature {
    icon: React.ComponentType;
    title: string;
    blurb: string;
    variation: FEATURE_VARIATION;
    footer?: string;
    featureImagePath?: string;
}

const FEATURES: Array<IFeature> = [
    {
        icon: TapInSVG,
        title: 'Sign out & return your keys in seconds',
        blurb: `No more manually filling out log books or navigating through endless PMS screens to sign out a key. Using our scannable key fobs and kiosk, keys can be signed out in seconds*. 
        
Just grab your keys, tap, sign, and go.`,
        variation: FEATURE_VARIATION.LARGE,
        footer:
            '*Avg. checkout time with Keyhive is 7 seconds. Avg. return time is 3 seconds.',
        featureImagePath: SignOutAndReturnKeysImage
    },
    {
        icon: ReportingSVG,
        title: 'Your keys. Under control',
        blurb: `“Has anyone seen that key?”
“Who had the key last?”
“How many keys do we have out?”
“Has Mike been chased about that key yet?”

Our beautiful dashboard provides you with information you need, when you need it.

You’re in control.`,
        variation: FEATURE_VARIATION.LARGE,
        featureImagePath: YourKeysUnderControlImage
    },
    {
        icon: OverdueSVG,
        title: 'Automatically chase late keys',
        blurb: `Stop wasting time chasing contractors and staff. Keyhive automatically sends a friendly reminder via text or email if someone is late late returning`,
        variation: FEATURE_VARIATION.LARGE,
        featureImagePath: ChaseLateKeysImage
    },
    {
        icon: AccountabilitySVG,
        title: 'Accountable check outs',
        blurb: `All keys checked out with Keyhive will be time stamped and signed for. You can even take a photograph. Smile!`,
        variation: FEATURE_VARIATION.LARGE,
        featureImagePath: AccountabilityImage
    },
    {
        icon: SetYourselfSVG,
        title: 'Set yourself apart ',
        blurb:
            'Once you’re setup and using our system, we’ll issue you a <b>Keyhive Secure</b> certificate. Another way to demonstrate you go above and beyond for your customers.',
        variation: FEATURE_VARIATION.SMALL
    },
    {
        icon: SaveMoneySVG,
        title: 'Save money and time',
        blurb:
            'Stop wasting time chasing contractors & searching for keys. Gain control and focus on revenue generating tasks',
        variation: FEATURE_VARIATION.SMALL
    },
    {
        icon: OnboardingSVG,
        title: 'Painless onboarding',
        blurb:
            'No matter what your current system is, we’ll help to import your data and get you setup fast, and stress-free.',
        variation: FEATURE_VARIATION.SMALL
    }
];

const FeaturesNew: React.FC = () => {
    return (
        <div tw="bg-white relative z-10">
            <ContentContainer id="features" tw="flex flex-col items-center">
                <SectionHeading
                    title="Features"
                    subtitle="Welcome to the future of key management"
                />

                <div tw="lg:flex lg:flex-wrap lg:justify-center">
                    {FEATURES.map((feature, index) => {
                        switch (feature.variation) {
                            case FEATURE_VARIATION.LARGE:
                                return (
                                    <FeatureLarge
                                        {...feature}
                                        key={index}
                                        index={index}
                                    />
                                );
                            case FEATURE_VARIATION.SMALL:
                                return <Feature {...feature} key={index} />;
                        }
                    })}
                </div>
            </ContentContainer>
        </div>
    );
};

const FeatureLgContentCol = styled.div<{ index: number }>`
    ${tw`col-span-2 w-full -mt-16 md:mt-0 md:mb-0 last:mb-0 md:pb-8 order-1 z-10 flex flex-col`};
    @media (min-width: ${theme('screens.md')}) {
        order: ${props => (props.index % 2 === 0 ? '1' : '2')};
    }
`;

const FeatureLgContentColInner = styled.div`
    ${tw`h-full flex-grow flex flex-col items-center p-0`};
    ${FEATURE_DROP_SHADOW};
`;

const FeatureLgContentFooter = styled.p`
    ${tw`text-sm text-gray-400 pt-4`};
`;

const FeatureLgImageCol = styled.div<{ index: number }>`
    ${tw`h-64 md:h-full lg:h-featureImageLarge mx-4 md:mx-0 col-span-3 rounded-lg drop-shadow-md z-0 md:z-20 `};
    ${FEATURE_DROP_SHADOW};
    @media (min-width: ${theme('screens.md')}) {
        order: ${props => (props.index % 2 === 0 ? '2' : '1')};
    }
`;

const FeatureLarge: React.FC<{ index: number } & IFeature> = ({
    icon: Icon,
    title,
    blurb,
    variation,
    footer,
    index,
    featureImagePath
}) => {
    return (
        <div tw="grid grid-cols-1 md:grid-cols-5 mb-16 auto-rows-min">
            <FeatureLgContentCol index={index}>
                <FeatureLgContentColInner>
                    <Icon tw="relative z-20" />
                    <div
                        css={[
                            tw`w-full relative z-10 bg-greyBg -mt-8 px-6 py-8 pt-12 text-center flex-grow rounded-lg`,
                            index % 2 === 0
                                ? tw`md:rounded-l-lg md:rounded-r-none`
                                : tw`md:rounded-r-lg md:rounded-l-none`
                        ]}
                    >
                        <h3 tw="text-2xl font-bold mb-8">{title}</h3>
                        <p tw="text-gray-500 text-base text-left whitespace-pre-wrap">
                            {blurb}
                        </p>
                    </div>
                </FeatureLgContentColInner>
                {footer && (
                    <FeatureLgContentFooter>{footer}</FeatureLgContentFooter>
                )}
            </FeatureLgContentCol>
            <FeatureLgImageCol
                index={index}
                css={css`
                    background-image: url(${featureImagePath});
                    ${tw`bg-cover bg-left-bottom`};
                `}
            />
        </div>
    );
};

const Feature: React.FC<IFeature> = ({ icon: Icon, title, blurb, footer }) => (
    <div
        tw="w-full mb-6 lg:mb-0 last:mb-0 lg:w-1/3 flex flex-col items-center lg:pr-12  last:pr-0"
        css={FEATURE_DROP_SHADOW}
    >
        <Icon tw="relative z-20" />
        <div tw="w-full flex-grow relative z-10 bg-greyBg rounded-md -mt-8 px-6 py-8 pt-12 text-center">
            <h3 tw="text-lg font-bold mb-4">{title}</h3>
            <p
                tw="text-gray-500 text-base"
                dangerouslySetInnerHTML={{ __html: blurb }}
            />
        </div>
    </div>
);

export default FeaturesNew;
