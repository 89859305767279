import React, { useContext } from 'react';
import { Disclosure } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import tw from 'twin.macro';
import { Link } from 'gatsby';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import LogoSVG from '../assets/inline/logo.svg';
import { RegisterInterestContext } from '../context/register-interest.context';

function classNames(...classes) {
    return classes.filter(Boolean).join(' ');
}
const ResponsiveLogo = styled(LogoSVG)`
    ${tw`w-32 md:w-48 h-full`}
`;
const linkProps = {
    activeClassName: 'link-active',
    css: css`
        ${tw`text-white inline-flex items-center px-1 pt-1 border-b-2 text-base font-medium border-transparent hover:border-indigo-500`}
        &.link-active {
            ${tw`border-brandOrange`}
        }
    `
};
const linkPropsMobile = {
    css: tw`border-transparent text-white hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700 block pl-3 pr-4 py-2 border-l-4 text-base font-medium`
};

export enum HEADER_VARIANT {
    HOMEPAGE = 'HOMEPAGE',
    DEFAULT = 'DEFAULT'
}

const Header: React.FC<{
    variant: HEADER_VARIANT;
}> = ({ variant, ...rest }) => {
    return (
        <Disclosure
            as="nav"
            css={css`
                ${tw`bg-transparent w-full absolute z-20 p-0 md:pt-4`}
            `}
        >
            {({ open }) => (
                <div tw="container mx-auto px-2">
                    <div
                        css={css`
                            ${open
                                ? `
                                backdrop-filter: blur(16px) saturate(120%);
                                -webkit-backdrop-filter: blur(16px) saturate(120%);`
                                : ''}
                        `}
                    >
                        <div
                            css={css`
                                ${tw`container mx-auto`}
                            `}
                        >
                            <div css={tw`flex justify-between h-16 md:h-20`}>
                                <div css={tw`flex w-full items-stretch`}>
                                    <div
                                        css={tw`flex-shrink-0 flex items-center mr-6`}
                                    >
                                        <Link
                                            to="/"
                                            css={css`
                                                transition: filter 0.1s
                                                    ease-in-out;
                                                &:hover {
                                                    filter: drop-shadow(
                                                        0px 0px 2px #ed8019
                                                    );
                                                }
                                            `}
                                        >
                                            <ResponsiveLogo />
                                        </Link>
                                    </div>
                                    <div
                                        css={tw`hidden md:ml-6 md:flex sm:space-x-8 md:mt-8 md:flex-grow md:w-full items-stretch`}
                                    >
                                        {/* Current: "border-indigo-500 text-gray-900", Default: "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700" */}
                                        <div
                                            css={
                                                variant ==
                                                HEADER_VARIANT.HOMEPAGE
                                                    ? tw`self-start mr-auto h-full sm:flex sm:space-x-8`
                                                    : tw`ml-auto h-full sm:flex sm:space-x-8`
                                            }
                                        >
                                            <HomepageAnchorLinks
                                                linkProps={linkProps}
                                            />
                                        </div>
                                        <NavLinks linkProps={linkProps} />
                                    </div>
                                </div>

                                <div
                                    css={tw`-mr-2 flex items-center md:hidden`}
                                >
                                    {/* Mobile menu button */}
                                    <Disclosure.Button
                                        css={tw`inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500`}
                                    >
                                        <span css={tw`sr-only`}>
                                            Open main menu
                                        </span>
                                        {open ? (
                                            <XMarkIcon
                                                css={tw`block h-6 w-6`}
                                                aria-hidden="true"
                                            />
                                        ) : (
                                            <Bars3Icon
                                                css={tw`block h-6 w-6`}
                                                aria-hidden="true"
                                            />
                                        )}
                                    </Disclosure.Button>
                                </div>
                            </div>
                        </div>

                        {/* MOBILE MENU */}
                        <Disclosure.Panel css={tw`md:hidden`}>
                            <div css={tw`pt-2 pb-3 space-y-1`}>
                                {/* Current: "bg-indigo-50 border-indigo-500 text-indigo-700", Default: "border-transparent text-gray-500 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-700" */}
                                <HomepageAnchorLinks
                                    linkProps={linkPropsMobile}
                                />
                                <NavLinks linkProps={linkPropsMobile} />
                            </div>
                        </Disclosure.Panel>
                    </div>
                </div>
            )}
        </Disclosure>
    );
};

const HomepageAnchorLinks = ({ linkProps }) => (
    <>
        <Link to="/#features" {...linkProps}>
            Features
        </Link>
        <Link to="/#pricing" {...linkProps}>
            Pricing
        </Link>
        <Link to="/blog" {...linkProps}>
            Blog
        </Link>
    </>
);

const NavLinks = ({ linkProps }) => {
    const { setShowModal } = useContext(RegisterInterestContext);

    return (
        <>
            <a href="#" {...linkProps} onClick={() => setShowModal(true)}>
                Request a demo
            </a>
            <a href="https://admin.keyhive.io" {...linkProps}>
                Login
            </a>
            <a href="#" {...linkProps} onClick={() => setShowModal(true)}>
                Sign up
            </a>
        </>
    );
};

export default Header;
