import React, { useRef, useState } from 'react';
import styled from '@emotion/styled';
import tw from 'twin.macro';
import { css } from '@emotion/react';
import { useMediaQuery } from '../hooks/useMediaQuery';
import { up } from '../utils/screens';
import VideoEmbed from './video-embed.component';

// const VideoContainer = styled.div<{
//     playerReady: boolean;
//     showPlayer: boolean;
// }>`
//     align-self: center;
//     justify-self: center;
//     position: absolute;
//     z-index: 9;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     transition: box-shadow ease-in-out 0.5s, opacity ease-in-out 0.5s;
//     box-shadow: ${({ showPlayer }) =>
//         showPlayer ? '0 0 20px black' : `0 0 0`};
//     }
//     ${VideoWrapper}:hover & {
//       box-shadow: 0 0 20px black;
//     }
//     ${tw`sm:w-full container lg:w-vidFull`}
// `;

const VideoInner = styled.div<{ showPlayer: boolean; isUpLg: boolean }>(
    ({ showPlayer, isUpLg }) => {
        if (showPlayer && isUpLg)
            return css`
                position: fixed;
                top: 20vh;
                left: 15vw;
                width: 70vw;
                ${tw`flex items-center justify-center rounded-md overflow-hidden z-40`}
            `;
        else
            return css`
                position: relative;
                display: block;
                width: 100%;
                height: 100%;
                ${tw`flex items-center justify-center rounded-md overflow-hidden`}
            `;
    }
);

// const PlayButton = styled.div<{ showPlayer: boolean }>`
//     align-self: center;
//     justify-self: center;
//     position: absolute;
//     z-index: ${({ showPlayer }) => (showPlayer ? -1 : 99)};
//     height: 90px;
//     width: 80px;
//     background-image: url(${PlayButtonSVG as any});
//     background-size: cover;
//     cursor: pointer;
//     filter: drop-shadow(0 0 8px black);
//     transition: filter ease-in-out 0.5s;
//     &:hover {
//         filter: drop-shadow(0 0 8px white);
//     }
// `;
//
// const StyledVideo = styled.video`
//     position: relative;
//     background-color: #1e1e1e;
//     .vjs-big-play-button {
//         display: none;
//     }
// `;

// const cfSafeUri = uri => `${uri}?cfOrigin=${window.location.href}`;

const HeroVideo: React.FC = () => {
    // const [playerReady, setPlayerReady] = useState<boolean>(false);
    const [showPlayer, setShowPlayer] = useState<boolean>(false);
    // const video = useRef<HTMLVideoElement>(null);
    // const player = useRef<videojs.Player>(null);
    const iframe = useRef<HTMLIFrameElement>(null);
    // const embedPlayer = useRef<VimeoPlayer>(null);

    const isUpLg = useMediaQuery(up('md'));

    // useEffect(() => {
    //     player.current = videojs(video.current, {
    //         html5: {
    //             vhs: {
    //                 withCredentials: false,
    //                 useDevicePixelRatio: true,
    //                 smoothQualityChange: true,
    //                 enableLowInitialPlaylist: true
    //             }
    //         },
    //         autoplay: false,
    //         controls: false,
    //         preload: 'auto',
    //         fluid: true,
    //         nativeControlsForTouch: true,
    //         techOrder: ['html5'],
    //         sources: [
    //             {
    //                 src: cfSafeUri(
    //                     'https://assets.keyhive.io/video/keyhive-promo-cmaf/keyhive-promo.m3u8'
    //                 ),
    //                 type: 'application/x-mpegURL'
    //             },
    //             {
    //                 src: cfSafeUri(
    //                     'https://assets.keyhive.io/video/keyhive-promo_720p_h264_aac.mp4'
    //                 ),
    //                 type: 'video/mp4'
    //             }
    //         ]
    //     });
    //
    //     player.current.on('ready', () => {
    //         // @ts-ignore
    //         player.current.tech().vhs.xhr.beforeRequest = ({
    //             uri,
    //             ...rest
    //         }) => ({
    //             ...rest,
    //             uri: cfSafeUri(uri)
    //         });
    //     });
    //
    //     player.current.on('canplay', () => {
    //         // Chrome
    //         setPlayerReady(true);
    //     });
    //
    //     player.current.on('loadedmetadata', () => {
    //         // Safari IOS
    //         setPlayerReady(true);
    //     });
    //
    //     player.current.on('ended', () => {
    //         setTimeout(setShowPlayer.bind(null, false), 1000);
    //     });
    //
    //     player.current.on('stop', () => {
    //         player.current.controls(false);
    //         setShowPlayer(false);
    //     });
    //
    //     return () => {
    //         player.current?.dispose();
    //     };
    // }, []);
    //
    // const _play = () => {
    //     setShowPlayer(true);
    //     player.current.play();
    //     player.current.controls(true);
    // };
    //
    // const _onDocumentClick = () => {
    //     if (showPlayer) {
    //         setShowPlayer(false);
    //         player.current.controls(false);
    //         player.current.pause();
    //         player.current.currentTime(0);
    //     }
    // };
    //
    // useDocumentClick(video, _onDocumentClick);

    // useEffect(() => {
    //     //Gets the iframe by id
    //     if (iframe.current) {
    //         embedPlayer.current = new VimeoPlayer(iframe.current);
    //
    //         embedPlayer.current.on('ready', function () {
    //             //Adds an event 'finish' that executes a function 'onFinish' when the video has ended.
    //             console.log('readyy!!!!');
    //         });
    //     }
    // }, [iframe]);
    //
    // const _play = () => {
    //     setShowPlayer(true);
    //     embedPlayer.current.play();
    // };

    return (
        <div tw="relative">
            {/*<VideoContainer playerReady={playerReady} showPlayer={showPlayer}>*/}
            <VideoInner showPlayer={showPlayer} isUpLg={isUpLg}>
                {/*<StyledVideo*/}
                {/*    ref={video}*/}
                {/*    tw="w-full h-full"*/}
                {/*    className="video-js vjs-16-9 vjs-theme-fantasy"*/}
                {/*/>*/}
                {/*</VideoContainer>*/}
                <VideoEmbed ref={iframe} />
                {/*{playerReady ? (*/}
                {/*    <PlayButton showPlayer={showPlayer} onClick={_play} />*/}
                {/*) : null}*/}
            </VideoInner>
            <div tw="w-full py-2 font-medium text-white text-center">
                Watch our 45 second intro video
            </div>
        </div>
    );
};

export default HeroVideo;
