import React from "react";
import "twin.macro";
import LogoSVG from "../../assets/inline/keyhive-logo-black.svg";
import KeywhereLogoSVG from "../../assets/inline/keywhere-logo.svg";
import CheckMarkDarkSVG from "../../assets/inline/check-mark-dark.svg";
import CheckMarkBrandSVG from "../../assets/inline/check-mark-brand.svg";

const data = [
  [
    "Prices",
    "£20 per 100 key fobs\n\rFrom £12pcm",
    "£1,000+ per 120 key cabinet\nFrom £35pcm\n",
  ],
  ["Online portal", true, true],
  ["Mobile app", true, true],
  ["Desktop kiosk", true, false],
  ["Automatically chases overdue keys", true, true],
  ["SMS reminders", true, true],
  ["White labelled solution", true, false],
  ["CRM Integration", true, true],
  ["Purchasable LED key cabinets", false, true],
  ["Adaptable to any key storage solution", true, false],
  ["White labelled key fobs", true, false],
];

export const KeywhereComparisonTable: React.FC<
  React.PropsWithChildren
> = () => (
  <div tw="container mx-auto p-8 flex justify-center">
    <table tw="table-auto w-full xl:w-2/3">
      <thead>
        <tr tw="h-16">
          <th />
          <th tw="border-l-2 border-gray-200">
            <div tw="flex items-center justify-center">
              <LogoSVG tw="w-40" />
            </div>
          </th>
          <th tw="border-l-2 border-gray-200">
            <div tw="flex items-center justify-center">
              <KeywhereLogoSVG tw="w-40" />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, i) => (
          <tr key={i} tw="border-t-2 border-gray-200 items-center h-16">
            {row.map((cell, j) => {
              if (j === 0)
                return (
                  <td key={j} tw="p-2 pr-8 text-base text-right">
                    {cell}
                  </td>
                );
              if (cell === true) {
                return (
                  <td
                    key={j}
                    tw="p-2 border-l-2 border-gray-200 flex-1 w-60 justify-center"
                  >
                    <div tw="flex justify-center">
                      {j === 1 ? (
                        <CheckMarkBrandSVG width={32} />
                      ) : (
                        <CheckMarkDarkSVG width={32} />
                      )}
                    </div>
                  </td>
                );
              }
              return (
                <td key={j} tw="p-2 border-l-2 border-gray-200 text-center">
                  <span tw="text-sm whitespace-pre">{cell}</span>
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);
