/** @jsx jsx */
import React, { useMemo } from "react";
import { css, jsx } from "@emotion/react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Hero } from "../components/hero.component";
import styled from "@emotion/styled";
import ReapitPNG from "../images/icons/integrations/reapit.png";
import GnomenPNG from "../images/icons/integrations/gnomen.png";
import tw from "twin.macro";
import Features from "../components/features.component";
import FeaturesNew from "../components/features-new.component";
import Pricing, { NewPricing } from "../components/pricing.component";
import Testimonial from "../components/testimonial.component";
import { RegisterInterestContextProvider } from "../context/register-interest.context";
import { HEADER_VARIANT } from "../components/header";

const INTEGRATIONS = [
  { name: "Reapit", logo: ReapitPNG },
  { name: "Gnomen", logo: GnomenPNG },
  // { name: 'AgentOS', logo: AgentProPNG },
  // { name: 'AgentPro', logo: AgentOs }
];

export const ContentContainer = tw.div`
    container mx-auto px-2 py-12 lg:py-24
`;

const DOTS_BG = css`
  background: radial-gradient(rgba(0, 0, 0, 0.15) 1px, transparent 2px);
  background-color: #f8f8f8;
  background-position: 0 0, 10px 10px;
  background-size: 30px 30px;
`;

export const CLIP_PATH_OFFSET = "6vw";

const DotsBg = styled.div`
  padding: ${CLIP_PATH_OFFSET} 0;
  width: 100%;
  ${DOTS_BG};
  clip-path: polygon(
    50% 0,
    100% ${CLIP_PATH_OFFSET},
    100% calc(100% - ${CLIP_PATH_OFFSET}),
    50% 100%,
    0 calc(100% - ${CLIP_PATH_OFFSET}),
    0 ${CLIP_PATH_OFFSET}
  );
  margin-bottom: calc((${CLIP_PATH_OFFSET}) * -1);
  position: relative;
  z-index: 2;
`;

const BenefitsList = styled.ul`
  ${tw`flex flex-row flex-wrap justify-center items-center py-8 lg:py-2`}
`;

const IntegrationsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  ${tw`py-8`}
`;

const IntegrationsList = styled.ul`
  width: 100%;
  ${tw`flex flex-col justify-center lg:flex-row pt-4`}
`;

const IntegrationsItem = styled.li`
  display: flex;
  justify-content: center;
  flex: 1 1 auto;
  ${tw`mb-4 lg:mb-0`}
`;

const IndexPage = () => {
  const newPricing = useMemo(() => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      return !!urlParams.get("newPricing");
    } else return false;
  }, []);

  return (
    <RegisterInterestContextProvider>
      <Layout headerVariant={HEADER_VARIANT.HOMEPAGE}>
        <SEO title="Home" />
        <Hero />
        <FeaturesNew />
        <Testimonial />
        {newPricing ? <NewPricing /> : <Pricing />}
      </Layout>
    </RegisterInterestContextProvider>
  );
};
export default IndexPage;
