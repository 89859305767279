import React, { useContext } from "react";
import styled from "@emotion/styled";
import { PalanquinBold } from "./global-styles.component";
import { RegisterInterestContext } from "../context/register-interest.context";
import "twin.macro";

export const FooterCTA = ({
  leadin = "Ready to gain control of your keys?",
  cta = "Arrange a demo today",
}) => {
  const { setShowModal } = useContext(RegisterInterestContext);

  return (
    <div tw="container mx-auto flex flex-col flex-grow items-center justify-center">
      <FooterCTAText tw="text-4xl text-white w-full text-center">
        {leadin}
      </FooterCTAText>
      <FooterCTAText tw="text-4xl text-brandOrange w-full text-center">
        {cta}
      </FooterCTAText>
      <button
        onClick={setShowModal.bind(null, true)}
        tw="mt-8 bg-white hover:bg-brandOrange h-12 border border-transparent rounded-md text-center text-text p-4 flex items-center justify-center leading-6 font-medium shadow-md"
      >
        Request a Demo
      </button>
    </div>
  );
};

const FooterCTAText = styled.h3`
  ${PalanquinBold}
`;
