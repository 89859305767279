import React from 'react';
import { Helmet } from 'react-helmet';

const SCRIPT_ONE = `
    _linkedin_partner_id = "4125217";
    window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
    window._linkedin_data_partner_ids.push(_linkedin_partner_id);
    `;
const SCRIPT_TWO = `
    (function(l) {
    if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
    window.lintrk.q=[]}
    var s = document.getElementsByTagName("script")[0];
    var b = document.createElement("script");
    b.type = "text/javascript";b.async = true;
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
    s.parentNode.insertBefore(b, s);})(window.lintrk);
`;

const LinkedInTag: React.FC = () => {
    return (
        <>
            <Helmet>
                <script type="text/javascript">{SCRIPT_ONE}</script>
                <script type="text/javascript">{SCRIPT_TWO}</script>
            </Helmet>
            <noscript>
                <img
                    height="1"
                    width="1"
                    style={{ display: 'none' }}
                    alt=""
                    src="https://px.ads.linkedin.com/collect/?pid=4125217&fmt=gif"
                />
            </noscript>
        </>
    );
};

export default LinkedInTag;
