import React from 'react';
import { css } from '@emotion/react';

const VideoEmbed = React.forwardRef<HTMLIFrameElement>((props, ref) => (
    <>
        <div
            css={css`
                padding: 56.25% 0 0 0;
                position: relative;
                width: 100%;
            `}
        >
            <iframe
                ref={ref}
                src="https://player.vimeo.com/video/544456528?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
                frameBorder="0"
                allow="autoplay; fullscreen; picture-in-picture"
                allowFullScreen
                css={css`
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                `}
                title="Keyhive.io - intro 2021"
            />
        </div>
        <script src="https://player.vimeo.com/api/player.js" />
    </>
));

export default VideoEmbed;
