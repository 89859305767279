import styled from '@emotion/styled';
import React from 'react';
import 'twin.macro';
import tw, { theme } from 'twin.macro';
import { PalanquinBold } from './global-styles.component';
import { graphql, useStaticQuery } from 'gatsby';

const STATS: Array<{ label: string; stat: number }> = [
    {
        label: 'Keys tracked',
        stat: 19242
    },
    {
        label: 'Properties managed',
        stat: 7402
    },
    {
        label: 'Checkouts optimsed',
        stat: 92418
    }
];

const StatContainer = styled.div`
    ${tw`flex flex-col place-items-center pb-6`}
    position: relative;
    &:after {
        bottom: 0;
        left: 50%;
        border: solid transparent;
        content: '';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(136, 183, 213, 0);
        border-bottom-color: #ffffff;
        border-width: ${theme('spacing.3')};
        margin-left: -${theme('spacing.3')};
    }
`;

const StatNumber = styled.h3`
    ${PalanquinBold};
    ${tw`text-xl md:text-5xl text-white text-center pb-2`};
    color: #d7baff;
`;

const StatLabel = styled.h4`
    ${PalanquinBold};
    ${tw`text-xs md:text-base text-white text-center`}
`;

const HeroStats: React.FC = () => (
    <>
        <StatLabel tw="mb-4">Trusted by property professionals</StatLabel>
        <div tw="grid grid-cols-3">
            {STATS.map(({ label, stat }, idx) => (
                <StatContainer key={idx}>
                    <StatNumber>{stat.toLocaleString()}</StatNumber>
                    <StatLabel>{label}</StatLabel>
                </StatContainer>
            ))}
        </div>
    </>
);

export default HeroStats;
