import React from 'react';
import 'twin.macro';
import { ContentContainer } from '../pages';
import OrbsFooterSVG from '../images/orbs-footer.svg';
import { OpenSans, Palanquin, PALETTE } from './global-styles.component';
import tw, { theme } from 'twin.macro';
import styled from '@emotion/styled';
import { FiveStar } from './hero.component';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const BlueOrbBGContainer = styled.div`
    background: url(${OrbsFooterSVG as any}), ${PALETTE.FOOTER_BG_BLUE};
    background-position: center;
    background-size: 80% auto;
    background-repeat: no-repeat;
    background-blend-mode: overlay;
`;

const BlockQuoteTitle = styled.h4`
    ${Palanquin};
    ${tw`text-4xl text-white font-bold mb-4`}
`;

const AvatarContainer = styled.div`
    ${tw`rounded-full w-32 h-32`}
`;
const AvatarName = styled.h4`
    ${Palanquin}
    ${tw`text-lg text-white text-center`}
`;
const AvatarPosition = styled.h4`
    ${OpenSans}
    ${tw`text-base text-gray-300 text-center font-light`}
`;

const TestimonialContainer = styled.div`
    background: linear-gradient(
        to bottom,
        ${theme('colors.white')} 50%,
        ${theme('colors.greyBg')} 50%
    );
`;

const Testimonial: React.FC = () => {
    const data = useStaticQuery(graphql`
        query {
            headshotImage: file(relativePath: { eq: "headshot_hammond.png" }) {
                childImageSharp {
                    fluid(maxWidth: 6000) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
        }
    `);

    return (
        <TestimonialContainer>
            <div tw="container mx-auto px-2">
                <BlueOrbBGContainer tw="flex flex-col lg:flex-row p-12 lg:py-24 rounded-lg">
                    <div tw="w-full lg:w-1/4 flex flex-col items-center pb-8">
                        <AvatarContainer>
                            <Img
                                fluid={data.headshotImage.childImageSharp.fluid}
                            />
                        </AvatarContainer>
                        <AvatarName>Joe Hammond</AvatarName>
                        <AvatarPosition>
                            Manager, Tomlinson Estates
                        </AvatarPosition>
                    </div>
                    <div tw="w-12 hidden lg:block">
                        <FontAwesomeIcon
                            icon={faQuoteLeft}
                            tw="text-white mr-1"
                            size={'2x'}
                        />
                    </div>
                    <div tw="w-full lg:w-3/4 flex flex-col text-center lg:text-left items-center lg:items-start">
                        <BlockQuoteTitle>
                            Revolutionary key management system
                        </BlockQuoteTitle>
                        <FiveStar size="lg" tw="flex items-center mb-4" />
                        <p tw="text-base text-white">
                            We have been using Keyhive for half a year now and
                            it is fantastic. I have used many different key
                            systems when visiting other estate agents and never
                            found any to be ‘stand out’. Keyhive simplifies the
                            whole process, allowing users to track keys online
                            and automatically remind contractors when they
                            should be returned, as well as much more. I would
                            highly recommend this product!
                        </p>
                    </div>
                </BlueOrbBGContainer>
            </div>
        </TestimonialContainer>
    );
};

export default Testimonial;
//
// export const InterestedFooter: React.FC = () => {
//     return (
//         <BlueOrbBGContainer>
//             <ContentContainer tw="flex flex-wrap items-center">
//                 <div tw="w-full lg:w-1/2 pb-12 lg:pb-0 pr-6 flex flex-col justify-center">
//                     <h3 tw="text-4xl text-white font-bold mb-4">Interested?</h3>
//                     <p tw="text-base text-white">
//                         Enter your email address and we’ll be in touch to
//                         arrange a personal demo for you and your organisation
//                     </p>
//                 </div>
//                 <div tw="w-full lg:w-1/2">
//                     <RegisterInterest
//                         buttonTW={tw`bg-footerButtonBlue hover:bg-opacity-80`}
//                     />
//                 </div>
//             </ContentContainer>
//         </BlueOrbBGContainer>
//     );
// };
