import React, { useEffect, useRef, useState } from 'react';
import OrbSVG from './../images/orb.svg';
import OrbsSVG from '../assets/orbs.svg';
import { css } from '@emotion/react';
import { ISourceOptions } from 'tsparticles';

const NUM_ORBS = 10;
const ANIM_SPEED = 0.55;
const TS_PARTICLES_CONFIG: ISourceOptions = {
    particles: {
        number: {
            value: NUM_ORBS,
            density: {
                enable: true,
                value_area: 800
            }
        },
        color: {
            value: '#ffffff'
        },
        shape: {
            type: 'image',
            image: {
                src: OrbSVG,
                width: 200,
                height: 200
            }
        },
        opacity: {
            value: 0.5,
            random: true,
            anim: {
                enable: false,
                speed: ANIM_SPEED,
                opacity_min: 0.1,
                sync: false
            }
        },
        size: {
            value: 200,
            random: true,
            anim: {
                enable: true,
                speed: ANIM_SPEED,
                size_min: 0.25,
                sync: false
            }
        },
        line_linked: {
            enable: false
        },
        move: {
            enable: true,
            speed: ANIM_SPEED,
            direction: 'none',
            random: false,
            straight: false,
            out_mode: 'out',
            bounce: false,
            attract: {
                enable: false,
                rotateX: 600,
                rotateY: 1200
            }
        },
        rotate: {
            random: true
        }
    },
    interactivity: {
        detect_on: 'window',
        events: {
            onhover: {
                enable: true,
                mode: 'bubble'
            }
        },
        modes: {
            bubble: {
                distance: 800,
                size: 160,
                duration: 2,
                opacity: 1
            }
        }
    },
    retinaDetect: true,
    fullScreen: false
};

const AnimatedOrbsBg: React.FC = () => {
    const canvasRef = useRef<HTMLCanvasElement>();
    const [showCanvas, setShowCanvas] = useState<boolean>(false);

    useEffect(() => {
        if (/Mobi/i.test(window.navigator.userAgent) === false) {
            setShowCanvas(true);
            import('tsparticles').then(({ tsParticles }) => {
                tsParticles.load('particles-js', TS_PARTICLES_CONFIG as any);
            });
        }
    }, []);

    return (
        <div
            id="particles-js"
            css={css`
                background: url(${OrbsSVG as any}),
                    linear-gradient(114.88deg, #351645 24.57%, #5a4faf 67.22%);
                width: 100%;
                display: flex;
                justify-content: center;

                position: absolute;
                background-size: contain;

                height: 100%;
                opacity: 1;
            `}
        >
            {showCanvas ? (
                <canvas
                    css={css`
                        background: linear-gradient(
                            114.88deg,
                            #351645 24.57%,
                            #5a4faf 67.22%
                        );
                        width: 100%;
                        position: relative;
                        mix-blend-mode: multiply;
                    `}
                    ref={canvasRef}
                />
            ) : null}
        </div>
    );
};

export default AnimatedOrbsBg;
