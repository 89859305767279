import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";
// import './layout.css';
import GlobalStyles from "./global-styles.component";
import Footer from "./../components/footer.component";
import { css } from "@emotion/react";
import Header, { HEADER_VARIANT } from "./header";
import WEB_FONT_URL from "./web-font-url";
import LinkedInTag from "./linked-in-tag.component";

const Layout: React.FC<
  React.PropsWithChildren<{ header?: boolean; headerVariant?: HEADER_VARIANT }>
> = ({ header = true, headerVariant = HEADER_VARIANT.DEFAULT, children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Helmet>
        <link href={WEB_FONT_URL} rel="stylesheet" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      <GlobalStyles />

      {/*<Header*/}
      {/*    siteTitle={data.site.siteMetadata?.title || `Title`}*/}
      {/*    css={css`*/}
      {/*        position: relative;*/}
      {/*        z-index: 2;*/}
      {/*    `}*/}
      {/*/>*/}
      {header && <Header variant={headerVariant} />}
      <main
        css={css`
          position: relative;
          z-index: 1;
        `}
      >
        {children}
      </main>
      <Footer />
      <LinkedInTag />
    </>
  );
};

export default Layout;
